module Main exposing (main)

import Browser
import Model
import Update
import View


main : Program Update.Flags Model.ModelWithCache Update.Msg
main =
    Browser.application
        { view = View.view
        , init = Update.init
        , update = Update.update
        , subscriptions = Update.subscriptions
        , onUrlChange = Update.ChangedUrl
        , onUrlRequest = Update.ClickedLink
        }
