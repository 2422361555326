class Survicate {
  constructor() {
    var s = document.createElement('script');
    s.src = 'https://survey.survicate.com/workspaces/99705cdcf6ee1d35801ea37176af1dbc/web_surveys.js';
    s.async = true;
    var e = document.getElementsByTagName('script')[0];
    e.parentNode.insertBefore(s, e);
  }
}

export default Survicate;
